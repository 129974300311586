<style lang="less" scoped>
.el-col {
  margin-bottom: 15px;
}
h5 {
  margin: 20px 0 10px;
}
</style>

<template>
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/list' }"
        >用户列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>用户详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container">
      <el-row>
        <el-col :span="8">系统账号: {{ info.username }}</el-col>
        <el-col :span="8">所属角色: {{ info.roleName }}</el-col>
        <el-col :span="8">真实姓名: {{ info.name }}</el-col>
      </el-row>

      <el-row>
        <el-col :span="8">联系电话: {{ info.mobile }}</el-col>
        <el-col :span="8">所属单位: {{ info.customerName }}</el-col>
        <el-col :span="8">备注: {{ info.remarks }}</el-col>
      </el-row>

      <!-- 我的家模块 -->
      <h5>我的家</h5>
      <el-table :data="dataList" class="list-table">
        <el-table-column prop="name" label="序号">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="houseName" label="名称"> </el-table-column>

        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUser(scope.row)"
              >用户列表
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      title="用户列表"
      :visible.sync="dialogVisible"
      
    >
       <el-table :data="userList" class="list-table">

        <el-table-column prop="name" label="序号">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="userName" label="用户名称"> </el-table-column>
       </el-table>
    </el-dialog>
  </div>
</template>
  
<script>
import { getUserById, getUserHouse } from "@/services/user";

export default {
  data() {
    return {
      dialogVisible: false,
      info: {},
      dataList: [],
      userList: [],
    };
  },

  methods: {
    submitDvc() {
      this.$refs.formContent.validate((valid) => {
        if (valid) {
          this.dialogShow = false;
          if (this.surplusInfo.lowRule === "true") {
            this.surplusInfo.lowRule = true;
          }
          if (this.surplusInfo.lowRule === "false") {
            this.surplusInfo.lowRule = false;
          }

          //console.log(this.surplusInfo)
          this.updateDvcType(this.surplusInfo);
        }
      });
    },

    formatTime(row) {
      return row && row.updateTime
        ? moment(row.updateTime).format("YYYY-MM-DD HH:mm:ss")
        : "";
    },

    handleUser(row) {
      this.userList = [];
      this.getHouseUser(row.houseId);
      this.dialogVisible = true
    },

    // 我的家用户
    async getHouseUser(houseId) {
      const responseData = await getUserHouse({ houseId });
      if (responseData.errorCode === 0) {
        this.userList = responseData.data || [];
      }
    },

    // 我的家
    async getHouse() {
      const userId = this.info.id;
      const responseData = await getUserHouse({ userId });
      if (responseData.errorCode === 0) {
        this.dataList = responseData.data || [];
      }
    },

    async getInfo(id) {
      const responseData = await getUserById(id);
      if (responseData.errorCode === 0) {
        this.info = responseData.data || {};
        this.getHouse();
      }
    },
  },

  created() {
    const id = this.$route.params.id;
    if (id) {
      this.getInfo(id);
    }
  },
};
</script>

